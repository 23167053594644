import { ConfirmationDialog } from 'component/base/ConfirmDialog';
import { useTranslation } from 'react-i18next';
import { TextField } from 'component/base/TextField';
import { useForm, FormProvider } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import { SetSiteLabelData, useSetSiteLabel } from 'api/site';
import { components } from 'openapi-types';

interface DomainLabelFormValue {
  label: string;
}

export function ChangeLabelTooltip({
  site,
  onClose,
}: {
  readonly site: Pick<components['schemas']['Site'], 'id' | 'label' | 'domain'>;
  readonly onClose: () => unknown;
}) {
  const { t } = useTranslation();
  const setSiteLabel = useSetSiteLabel(site.id!);
  const methods = useForm<DomainLabelFormValue>({
    defaultValues: {
      label: site?.label ?? '',
    },
    mode: 'onChange',
  });
  const { handleSubmit } = methods;

  const onSubmit = async (data: SetSiteLabelData) => {
    await setSiteLabel.mutateAsync({ ...data });

    onClose();
  };

  return (
    <ConfirmationDialog
      action="confirm"
      onConfirm={handleSubmit(onSubmit)}
      onClose={onClose}
      open={Boolean(site?.id)}
      title={t('change_site_label')}
      description={
        <>
          <Box marginBottom={1}>
            <Typography>{t('change_site_label_description', { domain: site?.domain })}</Typography>
          </Box>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextField
                name="label"
                label={t('site_label')}
                showLabel={false}
                fullWidth
                color="primary"
                rules={{ required: true }}
              />
            </form>
          </FormProvider>
        </>
      }
    />
  );
}
